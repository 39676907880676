
import fnApi from '/utils/common/methods'
import CardPayHead from './components/CardPayHead'
import CardPayCard from './components/CardPayCard'
import CardPayIcon from './components/CardPayIcon'
import CardPayForm from './components/CardPayForm'
import CardPayAddress from './components/CardPayAddress'
import CardPaySettle from './components/CardPaySettle'
import CardPaySubmit from './components/CardPaySubmit'

export default {
    name: 'CardPay',
    components: {
        CardPayHead,
        CardPayCard,
        CardPayIcon,
        CardPayForm,
        CardPayAddress,
        CardPaySettle,
        CardPaySubmit
    },
    data() {
        return {
            skeleton: true,
            hasLogin: false,    // 是否登录
            hasRequest: false,  // 是否正在提交
            hasOrderPage: false, // 是否内嵌在下单页
            middleEmit: {},
            fnApi: fnApi,
            query: {},
            tab: {
                active: 2,
                visible: false,
            },
            card: {            // 卡片
                visible: false,
                currentIndex: 0,
                current: {     // 当前选择的卡片信息
                    cvc: ''
                },
                list: [],
                ruleList: [],  // 卡片规则
                swiperCompo: { // swiper组件
                    options: {}
                }
            },
            icon: {            // 卡片icon
                list: []
            },
            address: {},       // 下单地址
            settle: {          // 结算明细数据
                list: []
            },
            orderInfo: {},     // 下单信息
            check: {           // 校验
                hasCheck: false, // 校验是否通过
                tip: '',       // 校验提示
            },
            publicKey: '',     // 秘钥
            form: {},          // 信用卡填写
            params: {},        // 提交参数
        }
    },
    head() {
        return this.setHead()
    },
    computed: {},
    watch: {},
    created() {
        this.hasOrderPage = this.$route.name == 'OrderConfirm'
    },
    mounted() {
        this.initialSet()
        this.setEmbedInitial()
    },
    methods: {
        // 获取订单详情
        getOrderInfoAjax() {
            this.$api.order.getOrderInfo({
                id: this.query.order_id,
                time: new Date().getTime()
            }).then(response => {
                const res = response.result || {};
                this.orderInfo = res
                this.skeleton = false
                this.setSettleData()
            })
        },
        // 获取卡片类型
        getCardIconAjax() {
            this.$api.order.getCardIcon().then(response => {
                const res = response.result || [];
                this.icon.list = res
            })
        },
        // 获取卡片规则
        getCardRulesAjax() {
            this.$api.order.getCardRules().then(response => {
                const res = response.result || [];
                this.card.ruleList = Object.entries(res[0]) || []
            })
        },
        // 获取卡片列表
        getCardListAjax() {
            this.$api.order.getCardList().then(response => {
                const res = response.result || [];
                this.tab.active = 1
                this.card.list = res || []
                this.card.visible = this.card.list.length
                this.tab.visible = this.card.visible
                !this.tab.visible && (this.tab.active = 2)
                this.setCardSwiper()
            })
        },
        cardPayAjax() { // 信用卡支付
            this.$api.order.setStripePay(this.params).then(response => {
                const res = response.result || [];
                this.hasRequest = false
                if(response.code != 'success') {
                    this.$VueScrollTo.scrollTo('.OrderPay', '', {
                        offset: -67
                    })
                    return
                }
                if(this.tab.active == 1) {
                    this.$refs.cardPaySubmit.popup.visible = false;
                }
                this.$storage.remove('order/cardPayInfo')
                this.$loading(false)
                this.$router.replace({
                    name: 'PaySuccess',
                    query: {
                        order_id: this.orderInfo.originOrderId
                    }
                })
            }).catch(err => {
                this.hasRequest = false
            })
        },
        // 设置card-swiper
        setCardSwiper() {
            this.card.swiperCompo.options = {
                loop: true,
                speed: 900,
                centeredSlides: true,
                slidesPerView: 2,
                spaceBetween: '-44%',
                effect : 'coverflow',
                grabCursor: true,
                coverflowEffect: {
                    rotate: 0,
                    stretch: '-194%',
                    depth: 260,
                    modifier: 1,
                    slideShadows : false
                },
                on: {
                    slideChange: (e) => {
                        this.$nextTick(()=> {
                            const ref = this.$refs.CardPayCard.$refs['CardPayCard-swiper'];
                            const swiper = ref.getSwiper();
                            this.card.currentIndex = swiper.realIndex
                            this.setCurrentCard()
                        })
                    }
                }
            }
        },
        // 选择信用卡
        setCurrentCard() {
            this.card.current = {
                cvc: '',
                ...this.card.list[this.card.currentIndex]
            }
            this.card.current.originAccount = this.card.current.desensitizeAccount
        },
        // 设置结算明细数据
        setSettleData() {
            this.settle.list.push({
                title: this.$translate('Order Number'),
                sub: `${this.orderInfo.originOrderId}`
            }, {
                title: this.$translate('Retail Price'),
                sub: `${this.$price(this.orderInfo.displayRetailPrice)}`,
                class: 'CardPaySettle-item--line'
            }, {
                title: this.$translate('Subtotal'),
                sub: `${this.$price(this.orderInfo.displaySubTotal)}`
            }, {
                title: this.$translate('Shipping Fee'),
                sub: this.orderInfo.shippingFee ? `${this.$price(this.orderInfo.displayShippingFee)}` : this.$translate('Free')
            }, {
                title: `${this.$translate('Coupon Discount')}<span>(${this.orderInfo.couponCode})</span>`,
                sub: `-${this.$price(this.orderInfo.displayCouponDiscount)}`,
                hasVisible: !this.orderInfo.couponCode,
                class: 'CardPaySettle-item--red'
            })
            if(this.orderInfo.onlinePayDiscount) {
                this.settle.list.push({
                    title: `${this.$translate('Online Payment Discount')}`,
                    sub: `-${this.$price(this.orderInfo.displayOnlinePayDiscount)}`,
                    class: 'CardPaySettle-item--red'
                })
            }
            this.settle.list.push({
                title: this.$translate('Total'),
                sub: `${this.$price(this.orderInfo.displayAmtCent)}`,
                class: 'CardPaySettle-item--red'
            })
        },
        // emit
        emitChangeTab() { // tab change
        },
        emitJumpAddress() {
            this.hasRequest = false
            this.$router.push({
                name: 'Address',
                query: {
                    key: 'order/billAddress',
                    order_id: this.query.order_id
                }
            })
        },
        emitPayClose() { // 关闭支付页
            this.hasRequest = false
            this.$router.go(-1)
        },
        // 提交
        emitHandleSubmit() {
            // 获取数据
            this.getFormData()
            this.checkForm().then(res => {
                if(this.check.hasCheck && !this.hasRequest) {
                    // 加密
                    this.hasRequest = true
                    this.setFormEncrypt()
                } else {
                    this.$VueScrollTo.scrollTo('body')
                }
            })
        },
        getFormData() { // 获取输入数据
            this.form = JSON.parse(JSON.stringify(this.$refs.CardPayForm.form))
        },
        checkForm() { // 校验
            return new Promise((resolve, reject) => {
                if(this.tab.active == 1) {
                    resolve(true)
                    return
                }
                this.$refs['CardPayForm'].$refs['form'].submit()
                this.fnApi.debounce(() => {
                    resolve(this.check.hasCheck)
                }, 10)
            })
        },
        setFormEncrypt() { // 加密
            this.$api.login.pubKey(2).then(response => {
                const res = response.result || {};
                this.publicKey = res
                if(response.code == 'success') {
                    // 设置提交参数
                    this.setParams()
                    // 信用卡支付
                    this.cardPayAjax()
                }
            }).catch(err => {
                this.hasRequest = false
            })
        },
        setParams() { // 设置支付参数
            let encryptFn = new JSEncrypt();
            encryptFn.setPublicKey(this.publicKey);
            let params = {
                customerEmail: this.orderInfo.buyerEmail,
                customerName: this.orderInfo.buyerEmail,
                amount: this.orderInfo.amtCent,
                currency: this.orderInfo.currency,
                orderSeq: this.orderInfo.originOrderId,
                saveCard: this.form.saveCard
            }
            if(this.tab.active == 2) {
                params.cvc = encryptFn.encrypt(this.form.cvc + '|' + new Date().getTime())
                params.account = encryptFn.encrypt(this.form.account + '|' + new Date().getTime())
                params.expYear = encryptFn.encrypt(this.form.expYear + '|' + new Date().getTime())
                params.expMonth = encryptFn.encrypt(this.form.expMonth + '|' + new Date().getTime())
            } else {
                params.cvc = encryptFn.encrypt(this.card.current.cvc + '|' + new Date().getTime())
                params.account = this.card.current.account
                params.expYear = this.card.current.expYear
                params.expMonth = this.card.current.expMonth
            }
            this.params = params
        },
        // 信用卡内嵌在下单页
        setEmbedInitial() { // 内嵌-初始化
            if(!this.hasOrderPage) {
                this.getOrderInfoAjax()
                this.getCardIconAjax()
                this.getCardRulesAjax()
                this.hasLogin && this.getCardListAjax()
            } else {
                this.getCardRulesAjax()
                this.setEmbedOther()
            }
        },
        setEmbedOther() { // 内嵌-其他设置
            this.skeleton = false
        },
        setEmbedSubmit(obj) { // 内嵌-提交
            this.orderInfo = obj || {}
            this.skeleton = false
            this.setSettleData()
            this.emitHandleSubmit()
        },
        // SEO HEAD
        setHead() {
            const hasOrderPage = this.$route.name == 'OrderConfirm';
            const head = this.$ctxApi.seoHead.initial({
                title: this.$translate('Payment'),
                keywords: this.$translate('Payment'),
                description: this.$translate('Payment'),
                image: 'https://cdn2.selleroa.com/yfn-upload/review/1694078645122.png'
            });
            const script = [{
                src: 'https://cdn2.selleroa.com/yfn-m/js/jsencrypt.min.js',
                async: true,
                defer: true
            }];
            return {
                ...(hasOrderPage ? {} : head),
                script
            }
        },
        // 初始化设置
        initialSet() {
            this.query = this.$route.query || {};
            this.currency = this.$storage.get('currency') || {}
            this.address = this.$storage.get('order/billAddress') || {}
            this.hasLogin = this.$login()
            this.middleEmit.fn = (fn, options) => {
                return this[fn](options)
            }
        }
    },
}
